<template>
    <div class="outer">
        <slot />
        <r-loading-bounce v-if="loading" />
        <header-nav :disableMyMaps="true">
            {{ title }}
            <template v-slot:breadcrumbs v-if="$auth.userIsRole(['Super Admin', 'Admin', 'Area Ops Manager'])">
                <div class="breadcrumbs">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                    <span>{{ title }}</span>
                </div>
            </template>
        </header-nav>
        <div class="outer">
            <template v-if="(maps && maps.length) || isSearching">
                <div class="map-toolbar">
                    <h2>Curated Maps</h2>
                    <div class="search-bar-wrapper">
                        <img class="search-icon" src="/images/search-icon.svg" alt="Search icon" />
                        <input type="text" placeholder="Search Maps" @input="onSearchChange" />
                    </div>
                    <button class="pill-btn pill-btn-icon" @click="openCreateMapModal">
                        <span class="pill-btn-text">New Map</span>
                    </button>
                </div>
                <div v-if="maps && maps.length">
                    <main class="content-container maps-list-page">
                        <div v-for="(map, index) in maps" :key="map.id" class="map-outer-wrapper">
                            <div class="map-inner-wrapper">
                                <div class="menu-icon-wrapper">
                                    <button class="menu-icon" @click="menuIsVisible = !menuIsVisible"></button>
                                    <div class="sub-menu" :class="{ 'menu-is-visible': menuIsVisible }">
                                        <router-link
                                            class="sub-menu-option"
                                            :to="'/dashboard/users/' + $route.params.user + '/maps/' + map.id"
                                        >
                                            Edit
                                        </router-link>
                                        <button class="sub-menu-option" @click="deleteMap(map.id, index)">
                                            Delete
                                        </button>
                                        <button
                                            class="sub-menu-option"
                                            @click="openDuplicateMapModal(map.id, map.title)"
                                        >
                                            Duplicate
                                        </button>
                                        <router-link
                                            class="sub-menu-option"
                                            :to="{ name: 'map', params: { map: map.id } }"
                                            target="_blank"
                                            >Open Shareable Map</router-link
                                        >
                                    </div>
                                </div>
                                <router-link :to="'/dashboard/users/' + $route.params.user + '/maps/' + map.id">
                                    <div class="map-top">
                                        <p>{{ map.title }}</p>
                                    </div>
                                    <div
                                        class="map-image"
                                        :style="{ 'background-image': 'url(' + map.mapUrl + ')' }"
                                    ></div>
                                </router-link>
                            </div>
                        </div>
                    </main>
                    <pagination
                        v-if="meta && !isSearching"
                        :total-pages="meta.last_page"
                        :total="meta.total"
                        :per-page="meta.per_page"
                        :current-page="meta.current_page"
                        @pageChanged="onPageChange"
                    />
                </div>
                <main class="content-container maps-list-page no-results" v-else>
                    <p>No Results Found.</p>
                </main>
            </template>

            <template v-else-if="!loading">
                <div class="empty-maps-container-outer">
                    <div class="empty-maps-container-inner">
                        <img src="/images/compass.svg" alt="Compass Icon" class="compass-icon inline-block" />
                        <p class="empty-maps-copy">You haven&rsquo;t curated any maps</p>
                        <button class="pill-btn pill-btn-icon" @click="openCreateMapModal">
                            <span class="pill-btn-text">Get Started</span>
                        </button>
                    </div>
                </div>
            </template>

            <create-map-modal v-if="showCreateMapModal" @close="showCreateMapModal = false" />
            <DuplicateMapModal
                v-if="showDuplicateMapModal"
                :base-map="duplicateMap"
                @close="showDuplicateMapModal = false"
            />

            <delete-confirmation-modal
                v-if="showDeleteConfirmationModal"
                open
                title="Delete Map"
                confirm-button-text="Delete"
                @cancel="showDeleteConfirmationModal = false"
                @confirm="onDeleteMapConfirm"
            >
                Are you sure you want to delete this map?
            </delete-confirmation-modal>
        </div>
    </div>
</template>

<script>
import Map from 'models/Map';
import User from 'models/User';
import { debounce } from 'utils/lodash';
import { MAPBOX_ACCESS_TOKEN } from 'config';
import HeaderNav from 'components/layout/header';
import CreateMapModal from 'modules/CreateMapModal';
import DuplicateMapModal from 'modules/DuplicateMapModal';
import pagination from 'components/Pagination';
import DeleteConfirmationModal from 'components/ui/modal/delete-confirmation-modal';
import IntercomMixin from '@/mixins/intercom.vue';

export default {
    components: {
        HeaderNav,
        CreateMapModal,
        DuplicateMapModal,
        pagination,
        DeleteConfirmationModal,
    },
    mixins: [IntercomMixin],
    props: {
        user: {
            type: [String, Number],
            required: true,
        },
    },
    computed: {
        showPagination() {
            return this.maps && this.maps.length > 15;
        },
        title() {
            return `${this.username} Curated Maps`;
        },
    },
    metaInfo() {
        return {
            title: this.title,
        };
    },
    created() {
        let page = this.$route.query.page ? this.$route.query.page : 1;
        User.get(this.user)
            .then((response) => {
                this.username =
                    response.data.data.first_name +
                    "'" +
                    (response.data.data.first_name.charAt(response.data.data.first_name.length - 1) === 's' ? '' : 's');
                this.fetchData(page);
            })
            .catch((error) => (this.error = error.toString()));
    },
    data() {
        return {
            appUrl: process.env.VUE_APP_APP_URL,
            loading: false,
            maps: null,
            meta: null,
            isSearching: false,
            tempMaps: null,
            menuIsVisible: false,
            error: null,
            showCreateMapModal: false,
            showDuplicateMapModal: false,
            duplicateMap: {
                id: null,
                title: null,
            },
            username: '',
            showDeleteConfirmationModal: false,
            mapToDelete: null,
            mapIndexToDelete: null,
        };
    },
    methods: {
        fetchData(page) {
            this.error = null;
            this.maps = null;
            this.loading = true;
            Map.all(this.user, page)
                .then(
                    (response) => (
                        (this.loading = false), (this.meta = response.data.meta), this.mapData(response.data.data)
                    )
                )
                .catch((error) => (this.error = error.toString()));
        },
        onPageChange(page) {
            this.$router.push({ name: 'user-maps', query: { page: page } });
            this.fetchData(page);
        },
        checkPage() {
            if (this.meta.current_page > this.meta.last_page) {
                this.onPageChange(this.meta.last_page);
            }
        },
        onSearchChange: debounce(function (input) {
            if (!this.isSearching) {
                this.tempMaps = this.maps;
                this.isSearching = true;
            }
            if (input.target.value === '') {
                this.maps = this.tempMaps;
                this.isSearching = false;
            } else {
                this.isSearching = true;
                Map.search(this.user, input.target.value)
                    .then((response) => ((this.maps = response.data.data), this.mapData(response.data.data)))
                    .catch((error) => {
                        return error;
                    });
            }
        }, 500),
        mapData(maps) {
            maps.map(function (map) {
                map.mapUrl =
                    'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/' +
                    map.center.longitude +
                    ',' +
                    map.center.latitude +
                    ',5,0,0/220x71?access_token=' +
                    MAPBOX_ACCESS_TOKEN;
            });
            this.maps = maps;
        },
        deleteMap(map, index) {
            this.mapToDelete = map;
            this.mapIndexToDelete = index;
            this.showDeleteConfirmationModal = true;
        },
        onDeleteMapConfirm() {
            this.loading = true;

            var currentMaps = this.maps;
            this.maps.splice(this.mapIndexToDelete, 1);
            Map.delete(this.user, this.mapToDelete).catch(
                (error) => (this.error = error.toString()),
                (this.maps = currentMaps)
            );

            this.mapToDelete = null;
            this.mapIndexToDelete = null;
            this.showDeleteConfirmationModal = false;
            this.loading = false;
        },
        openCreateMapModal: function () {
            this.showCreateMapModal = true;
        },
        openDuplicateMapModal: function (id, title) {
            this.duplicateMap.id = id;
            this.duplicateMap.title = title;
            this.showDuplicateMapModal = true;
        },
    },
    watch: {
        // call again the method if the route changes
        $route: 'fetchData',
    },
};
</script>

<style scoped>
/* Empty Maps Warning */
.empty-maps-container-outer {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.empty-maps-container-inner {
    margin: 10px;
}

.empty-maps-copy,
.compass-icon {
    margin: 0 auto 15px auto;
}

.compass-icon {
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 2px 2px 18px 2px rgba(206, 206, 206, 0.5);
}
.empty-maps-copy {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 300;
    color: #505152;
    font-style: oblique;
}
</style>
