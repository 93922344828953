<template>
    <r-modal open dismissable v-on="$listeners">
        <template #header>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ title }}
            </h3>
        </template>
        <template #icon>
            <div
                class="mx-auto flex-shrink-0 flex items-center h-10 w-10 justify-center bg-black rounded-full sm:mx-0"
            >
                <r-icon name="copy" size="sm" variant="white" />
            </div>
        </template>
        <template #footer>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <r-button variant="primary" @click="onConfirm">
                        {{ confirmButtonText }}
                    </r-button>
                </span>
                <span class="flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <r-button variant="white" @click="onCancel">
                        {{ cancelButtonText }}
                    </r-button>
                </span>
            </div>
        </template>
    </r-modal>
</template>

<script>
import Map from "models/Map";

export default {
  props: {
    baseMap: {
      type: Object,
      required: true
    },
    confirmButtonText: {
      type: String,
      default: 'Duplicate'
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    }
  },
  data: () => ({
    loading: false,
    config: {
      template_id: null,
      title: null
    }
  }),
  computed: {
    title() {
      return `Duplicate ${this.baseMap.title} Map`;
    }
  },
  created() {
    this.config.template_id = this.baseMap.id;
    this.config.title = this.baseMap.title;
  },
  methods: {
    onClose () {
      this.$emit('close');
    },
    onCancel () {
      this.$emit('close')
    },
    onConfirm () {
      if (this.loading) {
        return;
      }

      this.loading = true;

      Map.create(this.$route.params.user, this.config).then(
        response => {
          this.onClose();
          this.$router.push({
            name: "edit-map",
            params: {
              user: this.$route.params.user,
              map: response.data.data.id
            }
          });
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }
  }
};
</script>
