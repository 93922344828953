<template>
  <r-modal open title="New Map" @close="onClose">
    <r-loading-bounce v-if="loading" />
    <form @submit.prevent>
      <r-input-group label="Title" class="mb-6">
        <r-input
          v-model="config.title"
          :class="{ invalid: checkFormInputs.title }"
        />
      </r-input-group>
      <r-input-group label="Template" class="mb-6">
        <r-ui-select
          v-model="templateId"
          track-by="id"
          label="title"
          :options="templates"
        />
      </r-input-group>
      <div v-show="!config.template_id" class="destination">
        <r-input-group label="Destination Address" class="mb-6">
          <autocomplete
            :class="{ invalid: checkFormInputs.destination }"
            :source="geocodeSource"
            resultsProperty="features"
            :results-display="geocodeResultsDisplay"
            resultsValue="center"
            @selected="selectDestination"
          ></autocomplete>
        </r-input-group>
        <r-input-group label="Destination Title" class="mb-6">
          <r-input
            id="destination-title"
            class="input-field"
            type="text"
            v-model="config.destination_title"
            :class="{ invalid: checkFormInputs.destination_title }"
          />
        </r-input-group>
        <r-input-group label="Provider" class="mb-6">
          <r-ui-select v-model="config.provider" :options="mapProviders" />
        </r-input-group>
      </div>
      <r-button @click="onSave">Save</r-button>
    </form>
  </r-modal>
</template>

<script>
import { MAPBOX_ACCESS_TOKEN } from "config";
import Map from "models/Map";
import Autocomplete from "vuejs-auto-complete";
import { mapState } from "vuex";
import { find, get } from "@/utils/lodash";

export default {
  components: {
    Autocomplete
  },
  data: () => ({
    loading: true,
    templates: [],
    config: {
      id: null,
      title: null,
      destination: null,
      center: {
        longitude: null,
        latitude: null
      },
      provider: "Mapbox",
      layers: [],
      template_id: null
    },
    checkFormInputs: {
      title: false,
      destination: false,
      destination_title: false
    }
  }),
  computed: {
    ...mapState("mapProviders", ["mapProviders"]),
    templateId: {
      get() {
        return find(
          this.templates,
          template => template.id === this.config.template_id
        );
      },
      set(template) {
        this.config.template_id = get(template, "id", null);
      }
    }
  },
  async created() {
    const { data } = await Map.templates();

    this.templates = data.data;
    this.loading = false;
  },
  methods: {
    checkForm() {
      var isValid = true;

      for (var inputField in this.checkFormInputs) {
        if (this.checkFormInputs.hasOwnProperty(inputField)) {
          /*
           * When checking 'destination' input , see
           * if the form is using a template,
           * and skip if true.
           */
          if (inputField === "destination" && this.config.template_id) {
            this.checkFormInputs.destination = true;

            continue;
          }

          /*
           * When checking 'destination title' input , see
           * if the form is using a template,
           * and skip if true.
           */
          if (inputField === "destination_title" && this.config.template_id) {
            this.checkFormInputs.destination_title = true;

            continue;
          }
          // Check all other inputs.
          if (!this.config[inputField]) {
            this.checkFormInputs[inputField] = true;

            isValid = false;
          } else {
            this.checkFormInputs[inputField] = false;
          }
        }
      }

      return isValid;
    },
    onClose() {
      this.$emit("close");
    },
    geocodeSource: input => {
      return (
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        input +
        ".json?autocomplete=true&limit=10&access_token=" +
        MAPBOX_ACCESS_TOKEN
      );
    },
    geocodeResultsDisplay: result => {
      return result.place_name;
    },
    selectDestination(destination) {
      this.config.destination = destination.display;
      this.config.center.longitude = destination.value[0];
      this.config.center.latitude = destination.value[1];
    },
    onSave() {
      if (this.checkForm()) {
        if (this.loading) {
          return;
        }

        this.loading = true;

        var params = JSON.parse(JSON.stringify(this.config));

        if (params.template_id) {
          delete params.destination;
          delete params.center;
        }

        Map.create(this.$route.params.user, params).then(
          response => {
            this.onClose();
            this.$router.push({
              name: "edit-map",
              params: {
                user: this.$route.params.user,
                map: response.data.data.id
              }
            });
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    }
  }
};
</script>
<style lang="scss">
.destination {
  .autocomplete__box {
    border: 1px solid #e2e8f0 !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    border-radius: 0.25rem !important;
    color: #4a5568;
    width: 100%;
    line-height: 1.25 !important;
    padding: 0.5rem 0.75rem !important;
  }
}
</style>
